import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RecipeConstants } from '@gfs/constants';
import {
  CountableItem,
  CustomPortioningUnit,
  Duration,
  GeneralItem,
  IngredientType, MeasurementUnit,
  PrintableIngredientEntry,
  PrintableRecipeVM,
  Recipe, RecipeImagePostModel, RecipeIngredient,
  RecipeIngredientPriceRequest,
  RecipePrice,
  RecipePriceType, UIRecipeImage, Worksheet
} from '@gfs/shared-models';
import {
  CustomItemDataService,
  CustomItemService,
  GeneralItemService,
  IGlobalDialogsService,
  InjectionTokens,
  InventoryService,
  LocalizedValueUtilsService,
  MessageService,
  ProductService,
  RecipeService,
  WorksheetService
} from '@gfs/shared-services';
import { arrayToEntityDictionary, entityDictionaryToArray } from '@gfs/shared-services/core/entityUtil';
import { IdPAuthorize } from '@gfs/store/common';
import { GetAllCustomItemDataAttempt } from '@gfs/store/inventory/actions/worksheets.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DataLayerService } from '@recipe-ui/services/shared/google-tag-manager/data-layer.service';
import moment from 'moment';
import { BehaviorSubject, combineLatest, interval, Observable, of, } from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  filter,
  first,
  map,
  mergeMap,
  startWith,
  switchMap,
  tap,
  toArray,
  withLatestFrom
} from 'rxjs/operators';
import { GetCategoriesAttempt } from '../actions/category.actions';
import { GetMeasurementUnitsAttempt } from '../actions/measurementUnit.action';
import {
  ActionTypes,
  ActionUnion,
  CreateRecipeError, CreateRecipeSuccess,
  DeleteRecipeError,
  DeleteRecipeSuccess,
  DownloadRecipeSummaryError,
  DownloadRecipeSummarySuccess,
  GenericHttpError,
  GenericRecipeStoreUpdate,
  GetMostRecentWorksheetAttempt,
  GetMostRecentWorksheetError,
  GetMostRecentWorksheetSuccess,
  GetRecipeIngredientsAttempt,
  GetRecipeIngredientsSuccess,
  GetRecipesAttempt,
  GetRecipesError,
  GetRecipesSuccess,
  PatchRecipeAttempt,
  PatchRecipeError,
  PatchRecipeSuccess,
  PricingCalculationSuccess,
  RecalculateRecipePricing,
  RefreshActiveRecipeIngredientItemData,
  RefreshRecipePricingSuccess,
  SetActiveRecipeId,
  TryRecalculateRecipePricing
} from '../actions/recipe.actions';
import { AppState } from '../reducers';
import { GetProductInfoError } from '@gfs/store/feature/add-items';
import { RecipeReportService } from '../../../../../../apps/recipe-ui/src/app/services/recipe-report.service'


@Injectable()
export class RecipeEffects {
  static readonly duplicateCompleted$ = new BehaviorSubject(false);
  constructor(
    private actions$: Actions<ActionUnion>,
    private recipeService: RecipeService,
    private messageService: MessageService,
    private dataLayerService: DataLayerService,
    private productService: ProductService,
    private generalItemService: GeneralItemService,
    private customItemService: CustomItemService,
    private customItemDataService: CustomItemDataService,
    private inventoryService: InventoryService,
    private worksheetService: WorksheetService,
    private translate: TranslateService,
    private router: Router,
    private store: Store<AppState>,
    private RecipeReportService : RecipeReportService,
    @Inject(InjectionTokens.IGlobalDialogService) private globalDialogsService: IGlobalDialogsService
  ) { }


  // list-recipes
  getRecipes$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetRecipesAttempt),
    mergeMap(() => this.store.select(state => state.auth.pk).pipe(
      filter(pk => !!pk),
      concatMap(pk => this.recipeService.getRecipes(pk)),
      map((recipes) => recipes.map(x => ({ ...x, images: {} } as Recipe))),
      map((recipes) => new GetRecipesSuccess(recipes)),
      catchError((err) => of(new GetRecipesError(err)))
    ))));

  // create-recipe
  createRecipe$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.CreateRecipeAttempt),
    mergeMap((action) => {
      return this.recipeService
        .createRecipe(action.payload.recipe, action.payload.isDuplicate)
        .pipe(
          map(
            (newRecipe) =>
              new CreateRecipeSuccess({
                recipe: newRecipe,
                analyticsActionName: action.payload.analyticsActionName,
              })
          ),
          catchError((err) => of(new CreateRecipeError(err)))
        );
    })
  ));

  // create-recipe
  createRecipeSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.CreateRecipeSuccess),
    tap((action) => {
      this.router.navigateByUrl(`/recipe/${action.payload.recipe.id}`);
    })
  ), { dispatch: false });

  // create-recipe
  onRecipeSaveCompleted$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.CreateRecipeSuccess, ActionTypes.PatchRecipeSuccess),
    switchMap(({ payload }) => [
      new TryRecalculateRecipePricing(payload.recipe.id),
    ])
  ));

  // patch-recipe
  patchRecipe$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.PatchRecipeAttempt),
    concatMap((action) => this.handlePatchRecipeAction$(action)),
    mergeMap(x => x), // select the inner array (array of arrays => stream of arrays)
  ));

  // list-recipe
  deleteRecipe$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DeleteRecipeAttempt),
    withLatestFrom(this.store),
    mergeMap(([action, state]) => {
      return this.recipeService.deactivateRecipe(action.recipe).pipe(
        map(() => new DeleteRecipeSuccess(action.recipe)),
        catchError((err) => of(new DeleteRecipeError(err)))
      );
    })
  ));

  // load recipe inner data
  beginLoadIngredients$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.SetActiveRecipeId),
    map((action) => {
      return new GetRecipeIngredientsAttempt({ recipeId: action.nextId });
    })
  ));

  // load recipe inner data
  getRecipeIngredients$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetRecipeIngredientsAttempt),
    filter(
      (x) =>
        !!x.payload.recipeId &&
        x.payload?.recipeId !== RecipeConstants.NEW_ENTITY_ID_PLACEHOLDER
    ),
    withLatestFrom(this.store),
    mergeMap(([action, state]) => {
      return this.recipeService.getIngredientsByRecipeId(action.payload).pipe(
        map((ingredients) => new GetRecipeIngredientsSuccess({ ingredients })),
        catchError((err) => of(new GenericHttpError(err)))
      );
    })
  ));

  // load recipe inner data
  triggerRecipeIngredientItemDataLoad$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetRecipeIngredientsSuccess),
    filter((x) => x.payload.ingredients.length > 0),
    map(
      (action) =>
        new RefreshActiveRecipeIngredientItemData({
          ingredients: action.payload.ingredients.map((x) => ({
            itemType: x.itemType,
            itemId: x.itemId,
          })),
        })
    )
  ));

  // recipe inner data
  refreshRecipeIngredientsItemData$ = createEffect((): Observable<any> => this.actions$.pipe(
    ofType(ActionTypes.RefreshActiveRecipeIngredientItemData),
    map((action) => ({
      handledItemTypes: ['GFS', 'GENERAL', 'CUSTOM', 'RECIPE'],
      items: action.payload.ingredients.reduce((acc, cur) => [...acc, cur], []),
    })),
    withLatestFrom(this.store.select((x) => x.auth.pk)),
    map(([workload, pk]) =>
      workload.handledItemTypes
        .reduce(
          (acc, cur) => [
            ...acc,
            {
              itemType: cur,
              itemIds: workload.items
                .filter((y) => y.itemType === cur)
                .map((item) => item.itemId),
            },
          ],
          []
        )
        .filter((data) => data.itemIds.length > 0)
        .map((items) => {
          switch (items.itemType) {
            case 'GFS': {
              return of(items.itemIds as string[]).pipe(
                concatMap((j) => j),
                map((key) =>
                  this.productService.getProductInfo(key, pk).pipe(
                    map((x) => ({
                      ...x,
                      itemType: items.itemType,
                    })),
                    catchError((err) => {
                      return of(new GetProductInfoError(err));
                    })
                  )
                ),
                mergeMap((j) => j),
                toArray()
              );
            }
            case 'GENERAL': {
              return this.generalItemService
                .getGeneralItems(items.itemIds, false, pk)
                .pipe(
                  map((x) =>
                    x.map((y) => ({
                      ...y,
                      itemType: items.itemType,
                    }))
                  )
                );
            }
            case 'CUSTOM': {
              return this.customItemService
                .getCustomItems(items.itemIds, false, pk)
                .pipe(
                  tap((x) =>
                    x.map((y) =>
                      this.store.dispatch(
                        new RefreshActiveRecipeIngredientItemData({
                          ingredients: [{ itemType: 'GENERAL', itemId: y.id }],
                        })
                      )
                    )
                  ),
                  map((x) =>
                    x.map((y) => ({
                      ...y,
                      itemType: items.itemType,
                    }))
                  )
                );
            }
            case 'RECIPE': {
              // TODO: Create an api to get a list of recipes by ID to avoid wasted network
              return this.recipeService.getRecipes(pk).pipe(
                map((x) => x.filter((y) => items.itemIds.includes(y.id))),
                map((x) =>
                  x.map((y) => ({
                    ...y,
                    itemType: items.itemType,
                    subType: y.details.menu ? 'menuItem' : 'batch'
                  }))
                )
              );
            }
          }
        })
    ),
    switchMap((requests) => requests),
    mergeMap((items) => items),
    tap((returnedItems: any[]) => {
      const subProduct = returnedItems
        .map((nextItem) => {
          const nextItemType = nextItem.itemType;
          if (nextItemType === 'GENERAL') {
            const tempGeneralItem = nextItem as GeneralItem;
            const primaryProduct = tempGeneralItem.productList.find(
              (z) => z.primaryProduct
            );
            if (!!primaryProduct) {
              return {
                itemId: primaryProduct.id,
                itemType: primaryProduct.type,
              };
            }
          }
        })
        .filter((x) => !!x);

      if (subProduct.length) {
        this.store.dispatch(
          new RefreshActiveRecipeIngredientItemData({ ingredients: subProduct })
        );
      }
    }),
    switchMap((item) => item),
    map((d) => ({
      ...d,
      fragment: {
        gfsItem: null,
        recipeItem: null,
        customItem: null,
        generalItem: null,
        customItemData: null,
      } as CountableItem,
    })),
    map((d) => ({
      ...d,
      fragment: { ...d.fragment, ...CountableItem.repackCountableItem(d) },
    })),
    withLatestFrom(this.store.select((x) => x.auth.pk)),
    mergeMap(([data, customerPK]) => {
      return this.customItemDataService
        .getCustomItemData(customerPK, data.id, data.itemType)
        .pipe(
          withLatestFrom(of(data)),
          map(([customItemData, itemData]) => {
            itemData.fragment = {
              ...itemData.fragment,
              customItemData: [...customItemData, {}][0],
            };
            return {
              ...[...customItemData, {}][0],
              ...itemData,
              customItemData,
            };
          })
        );
    }),
    withLatestFrom(this.store.select((x) => x.auth.pk)),
    mergeMap(([data, customerPK]) => {
      if (data.itemType === 'CUSTOM') {
        return this.generalItemService
          .getGeneralItemByCustomItemId(data.id, customerPK)
          .pipe(
            withLatestFrom(of(data)),
            map(([generalItemData, itemData]) => {
              itemData.fragment = {
                ...itemData.fragment,
                generalItem: generalItemData ?? {},
              };
              return {
                ...itemData,
              };
            })
          );
      }
      return of({
        ...data,
      });
    }),
    mergeMap((data: any) => [
      new GenericRecipeStoreUpdate({
        valuePath: `ingredientItemData.${data.id}`,
        value: { ...data },
      }),
      new GenericRecipeStoreUpdate({
        valuePath: `ingredientCountableItemData.${data.id}`,
        value: { ...data.fragment },
      }),
    ]),
    catchError((err) => of(new GenericHttpError(err)))
  ));

  // the most recent work sheet is used to drive custom units?
  getMostRecentWorksheet$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetMostRecentWorksheetAttempt),
    withLatestFrom(this.store.select((state) => state.auth.pk)),
    mergeMap(([_, pk]) => {
      return this.inventoryService
        .getWorksheetSummaries(pk)
        .pipe(map((sheets) => this.sortWorksheetSummaries(sheets)));
    }),
    mergeMap((summaries: Worksheet[]) => {
      if (summaries.length > 0) {
        return this.worksheetService
          .getWorkSheet(summaries[0].id)
          .pipe(map((sheet) => new GetMostRecentWorksheetSuccess(sheet)));
      } else {
        return of(new GetMostRecentWorksheetSuccess(null));
      }
    }),
    catchError((error: HttpErrorResponse) => {
      if (error.status === 403) {
        // If permissions for inventory are missing, there is no recent worksheet
        return of(new GetMostRecentWorksheetSuccess(null));
      } else {
        return of(new GetMostRecentWorksheetError(error));
      }
    })
  ));

  // when ingredient data changes, automatically recalculate portion price
  createRecipeOnIngredientDataChange$ = createEffect(() => this.actions$.pipe(
    ofType(
      ActionTypes.GenericRecipeStoreUpdate,
    ),
    filter((action) => {
      return (
        (action as GenericRecipeStoreUpdate).payload.valuePath.includes(
          'ingredientItemData'
        ) ||
        (action as GenericRecipeStoreUpdate).payload.valuePath.includes(
          'ingredients'
        ) ||
        (action as GenericRecipeStoreUpdate).payload.valuePath.includes(
          'calculationConfig'
        )
      );
    }),
    withLatestFrom(this.store),
    mergeMap(([action, state]) => of(new TryRecalculateRecipePricing(state.recipe.currentRecipeId))
    ),
  ));

  createRecipeDynamicRecipePricingCalcDaemon$ = createEffect(() => this.actions$.pipe(
    ofType(
      ActionTypes.TryRecalculateRecipePricing
    ),
    map(({ recipeId }) => {
      return new RecalculateRecipePricing(recipeId);
    }),
  ));

  createRecipeRecalculateRecipePricing$ = createEffect(() => this.actions$.pipe(
    ofType(
      ActionTypes.RecalculateRecipePricing
    ),
    debounceTime(200), // This is a hack to prevent this call from occurring once per ingredient, and run once instead
    withLatestFrom(this.store),
    concatMap(([{ recipeId }, state]) => {
      const ingredientRequestBody: RecipeIngredientPriceRequest[] = entityDictionaryToArray<
        RecipeIngredient
      >(state.recipe.ingredients)
        .filter((x) => x.recipeId === recipeId)
        .filter((x) => !x.isDeleted)
        .map((recipeIngredients) => {
          return {
            ingredientType: recipeIngredients.itemType as IngredientType,
            ingredientId: recipeIngredients.itemId,
            // TODO: calculate yield percent once we start using it on the back-end
            yieldPercent: '30',
            unit: recipeIngredients.unit,
            quantity: +Number.parseFloat(recipeIngredients.quantity.toString().replace(',', '.')),
          };
        })
        .filter((item) => item.quantity > 0 && !!item.unit);

      if (ingredientRequestBody.length > 0) {
        return this.recipeService.priceCalculator({
          customerPK: state.auth.pk,
          ingredients: ingredientRequestBody,
          // TODO: get the calculation constant and price type from the form once we start totaling recipe cost in WTRM-976
          calculationConstantType:
            state.recipe.recipePricing?.[recipeId]
              ?.calculationConfig?.constantType ?? RecipePriceType.MENU_PRICE,
          calculationConstantValue:
            state.recipe.recipePricing?.[recipeId]
              ?.calculationConfig?.constantValue ?? '25',
        });
      } else {
        return of({
          recipeId: '',
          customerPK: state.auth.pk,
          foodCostPercent: 0,
          menuPrice: 0,
          totalCost: 0,
          ingredientPrices: [],
        });
      }
    }),
    map((pricing: RecipePrice) => ({
      ...pricing,
      foodCostPercent: +(pricing.foodCostPercent.toFixed(2)),
      menuPrice: +(pricing.menuPrice.toFixed(2)),
    })),
    map((pricing: RecipePrice) => {
      return new PricingCalculationSuccess(pricing);
    }),
    catchError((err) => of(new GenericHttpError(err)))
  ));

  // refresh recipe pricing information after recipe is saved
  // it might make sense to create a daemon service to do this so can contextually activate it
  appAutoRefreshRecipePriceDaemon$ = createEffect(() => this.actions$.pipe(
    ofType(
      ActionTypes.PatchRecipeSuccess,
      ActionTypes.CreateRecipeSuccess
    ),
    withLatestFrom(this.store),
    concatMap(([action, state]) => {
      return this.recipeService.refreshRecipePriceById(
        action.payload.recipe.id
      );
    }),
    map((pricing) => {
      return new RefreshRecipePricingSuccess(pricing);
    }),
    catchError((err) => of(new GenericHttpError(err)))
  ));

  // send analytics data
  logInAnalytics$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.CreateRecipeSuccess, ActionTypes.PatchRecipeSuccess),
    withLatestFrom(
      this.store.select((state) => ({
        categories: state.category.categories,
      }))
    ),
    tap(([action, state]) => {
      const recipe = action.payload.recipe;
      const category = state.categories[recipe.categoryId];
      const dataLayerPayload = Object.assign({}, recipe, {
        categoryName: category ? category.name : null,
      });
      this.dataLayerService.push({
        event: action.payload.analyticsActionName,
        values: dataLayerPayload,
      });
    })
  ), { dispatch: false });

  // global/list-recipes
  cloneRecipe$ = createEffect((): Observable<any> => this.actions$.pipe(
    ofType(ActionTypes.CloneRecipeAttempt),
    withLatestFrom(
      this.store.select((state) => ({
        recipes: state.recipe.recipes,
        recipeNames: entityDictionaryToArray<Recipe>(
          state.recipe.recipes
        ).reduce(
          (acc, next) => ({
            ...acc,
            [next.name]: this.translate.instant('ADD_ITEMS.COPY_OF', {
              itemName: next.name,
            }),
          }),
          {}
        ),
      }))
    ),
    map(([action, state]) => {
      return { action, state };
    }),
    mergeMap((opContext) =>
      this.recipeService
        .getIngredientsByRecipeId({ recipeId: opContext.action.sourceRecipeId })
        .pipe(
          withLatestFrom(of(opContext)),
          map(([ingredients, context]) => ({ ...context, ingredients }))
        )
    ),
    map(({ action, state, ingredients }) => {
      let sourceName = state.recipes[action.sourceRecipeId].name;
      let newName = null;
      while (sourceName != null) {
        sourceName = state.recipeNames[sourceName];
        newName = sourceName ?? newName;
      }
      return {
        recipe: {
          ...state.recipes[action.sourceRecipeId],
          name: newName,
          id: action.destinationRecipeId,
        },
        ingredients: ingredients.map((ingredient, idx) => ({
          ...ingredient,
          id: `${action.destinationRecipeId}_${idx}`,
          recipeId: action.destinationRecipeId,
        })),
      };
    }),
    mergeMap((opContext) =>
      this.recipeService.createRecipe(opContext.recipe, false).pipe(
        withLatestFrom(of({ ingredients: opContext.ingredients })),
        map(([recipe, ingredients]) => ({ recipe, ...ingredients }))
      )
    ),
    map((opContex) => {
      opContex.ingredients.forEach((element) => {
        element.recipeId = opContex.recipe.id;
      });
      return opContex;
    }),
    mergeMap((opContext) =>
      this.recipeService
        .createIngredientsForRecipe({
          recipeId: opContext.recipe.id,
          ingredients: opContext.ingredients,
        })
        .pipe(
          withLatestFrom(of(opContext.recipe)),
          map(([newIngredients, recipe]) => ({
            recipe,
            ingredients: newIngredients,
          }))
        )
    ),
    mergeMap((opContext) =>
      this.recipeService.refreshRecipePriceById(opContext.recipe.id).pipe(
        withLatestFrom(of(opContext)),
        map(([recipePrice, { recipe, ingredients }]) => ({
          recipe: { ...recipe, recipePrice },
          ingredients,
        })),
        tap((data)=> RecipeEffects.duplicateCompleted$.next(false))
      )
    ),
    switchMap((data) => [
      new GenericRecipeStoreUpdate({
        valuePath: `recipes.${data.recipe.id}`,
        value: data.recipe,
      }),
    ])
  ));

  recipeActionLogger$ = createEffect(() => this.actions$.pipe(
    ofType(
      ActionTypes.GenericHttpError,
      ActionTypes.GetRecipesError,
      ActionTypes.CreateRecipeError,
      ActionTypes.PatchRecipeError,
      ActionTypes.DeleteRecipeError
    ),
    tap((err) => {
      this.messageService.queue(err.error.message);
    })
  ), { dispatch: false });

  ms24Hr = 86400000;
  autoRefreshCalculationThreshhold = interval(480 * (60 * 1000)).pipe(
    startWith(+new Date() - this.ms24Hr),
    map((_) => +new Date() - this.ms24Hr)
  );

  recipeLastModifiedDateSource = this.store
    .select((state: AppState) => {
      return state.recipe.recipes;
    })
    .pipe(
      switchMap((x) => entityDictionaryToArray<Recipe>(x)),
      filter(x => !x?.preventAutomatedPricingCalculation),
      filter((x) => !!x?.recipePrice?.lastUpdated),
      map((x) => ({
        recipeId: x.id,
        recipe: x,
        lastUpdatedUTC: Date.parse(x.recipePrice.lastUpdated),
      }))
    );

  // passive global
  // when a recipe is loaded we need to recalculate its menu price if it is stale
  recipeAutoRecalcDaemon$ = createEffect(() => combineLatest([
    this.recipeLastModifiedDateSource,
    this.autoRefreshCalculationThreshhold,
  ]).pipe(
    filter(
      ([{ lastUpdatedUTC, }, refreshThreshold]) =>
        lastUpdatedUTC < refreshThreshold
    ),
    mergeMap(([{ recipeId, recipe }]) =>
      this.recipeService.refreshRecipePriceById(recipeId).pipe(
        withLatestFrom(of(recipe)),
        map(([newRecipePrice, theRecipe]) => {
          return [
            new GenericRecipeStoreUpdate({
              valuePath: `recipe.recipes.${theRecipe.id}.recipePrice`,
              value: newRecipePrice,
            }),
            new GenericRecipeStoreUpdate({
              valuePath: `recipe.recipePricing.${theRecipe.id}`,
              value: newRecipePrice,
            }),
          ];
        }),
        catchError((err) => {
          return of([
            new GenericRecipeStoreUpdate({
              valuePath: `recipe.recipes.${recipeId}.preventAutomatedPricingCalculation`,
              value: true,
            })
          ]);
        })
      )
    ),
    switchMap((x) => x),
    map((action) => action)
  ));

  recipeLoading$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.RecipeLoading),
    concatMap(({ recipeId }) => [
      new GetMeasurementUnitsAttempt(),
      new GetCategoriesAttempt(),
      new GetRecipesAttempt(),
      new SetActiveRecipeId(recipeId),
      new GetMostRecentWorksheetAttempt(),
      new GetAllCustomItemDataAttempt(),
    ])
  ));

  // create-recipe
  // needs to work globally but the current architecture for loading up a full recipe isnt compatible
  onPrintRecipeBegin$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.PrintRecipeBegin),
    withLatestFrom(
      this.store.select((state) => ({
        lang: state.layout.language,
        recipe: (state.recipe?.recipes ?? {})[state.recipe?.currentRecipeId],
        ingredients: entityDictionaryToArray<RecipeIngredient>(
          state.recipe.ingredients
        )
          .filter(y => !y.isDeleted)
          .filter((y) => y.recipeId === state.recipe.currentRecipeId),
        measurementUnitDictionary: {
          ...state.measurementUnit.measurementUnits,
          ...arrayToEntityDictionary<MeasurementUnit>(state.worksheets.customItemData
            .map(next => next.recipeUnits
              .reduce((accum, portionUnit) => ([...accum, portionUnit.custom]), [] as Array<CustomPortioningUnit>)
              .map(innerNext => ({
                id: innerNext.id,
                name: [
                  {
                    languageCode: state.layout.language,
                    value: innerNext.name,
                  }
                ]
              } as MeasurementUnit)))
            .reduce((accum, unitArray) => ([...accum, ...unitArray]), ([] as Array<MeasurementUnit>)), x => x.id)
        },
        itemData: state.recipe.ingredientCountableItemData,
      }))
    ),
    map(([action, { recipe, ingredients, measurementUnitDictionary, itemData, lang }]) => ({
      recipe,
      lang,
      ingredients: ingredients.map(
        ({ itemId, quantity, unit }) => ({
          formattedMeasurement:
            this.formatPortionUnitQty(
              itemData[itemId],
              quantity,
            ) ??
            this.formatMeasurementUnitQty(
              quantity,
              measurementUnitDictionary[unit],
              lang
            ),
          ingredientName: itemData[itemId].customItem?.description ??
            itemData[itemId].generalItem?.description ??
            itemData[itemId].recipeItem?.name ??
            LocalizedValueUtilsService.resolveLocalizedValue(
              itemData[itemId].gfsItem?.description,
              lang
            ),
        } as PrintableIngredientEntry)
      ),
    })),
    map(viewData => ({
      orderedImageUriList: this.getRecipeImageUris(viewData.recipe),
      viewData
    })),
    map(
      ({ viewData, orderedImageUriList }): PrintableRecipeVM => ({
        isLoading: false,
        isReady: true,
        id: viewData.recipe.id,
        orderedImageUriList,
        prepInstructions: viewData.recipe.preparationsDetails?.prepInstructions,
        ingredients: viewData.ingredients,
        recipeName: viewData.recipe.name,
        formattedCookTime: this.formatDuration(
          viewData.recipe.preparationsDetails?.cookTime,
          viewData.lang
        ),
        formattedPrepTime: this.formatDuration(
          viewData.recipe.preparationsDetails?.prepTime,
          viewData.lang
        ),
      })
    ),
    switchMap((viewModel) => [
      new GenericRecipeStoreUpdate({
        valuePath: `printableRecipes.${viewModel.id}`,
        value: viewModel,
      }),
    ])
  ));

  // trigger reauthorization with okta as a side effect of these actions
  reauthorizeDaemon$ = createEffect( () => this.actions$.pipe(
    ofType(
      ActionTypes.CloneRecipeAttempt,
      ActionTypes.CreateRecipeAttempt,
      ActionTypes.PatchRecipeAttempt,
      ActionTypes.DeleteRecipeAttempt,
      ActionTypes.GetRecipesAttempt,
      ActionTypes.GetRecipeIngredientsAttempt,
      ActionTypes.CalculationStateLogAttempt,
    ),
    debounceTime(500),
    map(_ => new IdPAuthorize()),
  ));

  downloadRecipeSummaryReport$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DownloadRecipeSummaryReport),
    mergeMap((action) =>
      this.RecipeReportService
        .downloadRecipeSummaryReport(
          action.payload.locale,
          action.payload.customerPk,
          action.payload.customerName
        )
        .pipe(
          map((_) => new DownloadRecipeSummarySuccess()),
          tap((_) => this.globalDialogsService.closeLoadingModal()),
          catchError((err) => of(new DownloadRecipeSummaryError(err)))
        )
    )
  ));



  private sortWorksheetSummaries = (sheets: Worksheet[]) => {
    return sheets.sort((sheet1, sheet2) => {
      const created1 = moment(sheet1.created);
      const created2 = moment(sheet2.created);
      return created2.valueOf() - created1.valueOf();
    });
  };

  selectRecipeByKey = (state: AppState, recipeId: string): Recipe => (state.recipe?.recipes ?? {})[recipeId];

  handleHttpPatchRecipe$ = (action: PatchRecipeAttempt):
    Observable<(PatchRecipeSuccess | PatchRecipeError)[]> =>
    // any =>
    this.recipeService.patchRecipe(action.payload.recipe).pipe(
      map((recipe) => new PatchRecipeSuccess({
        recipe,
        analyticsActionName: action.payload.analyticsActionName,
      })),
      catchError((err) => of(new PatchRecipeError(err))),
      toArray()
    );

  handlePatchRecipeAction$ = (action: PatchRecipeAttempt): Observable<(PatchRecipeSuccess | PatchRecipeError)[]> =>
    this.handleHttpPatchRecipe$(action);

  mapToRecipeImagePostModel = ({ content, ordinal, isDeleted, isNew, key, file }: UIRecipeImage):
    RecipeImagePostModel => ({ content, ordinal, isDeleted, isNew, key, file } as RecipeImagePostModel);

  // Refactor: move to formatters
  private formatMeasurementUnitQty(
    qty: string,
    unit: MeasurementUnit,
    lang: string
  ): string {
    const unitSource = unit?.symbol?.length > 0 ? unit.symbol : unit?.name;
    if (!!unitSource) {
      return `${qty} ${LocalizedValueUtilsService.resolveLocalizedValue(unitSource, lang)}`;
    }
    return '';
  }

  // Refactor: move to formatters
  private formatPortionUnitQty(
    item: CountableItem,
    qty: string,
  ): string {
    if (!item?.gfsItem?.portionUom?.length) { return null; }
    return `${qty} ${item.gfsItem.portionUom}`;
  }

  // Refactor: move to formatters
  private formatDuration(d: Duration, lang: string) {
    let result = '';
    const hoursComponent = this.formatDurationComponent(
      d.hours,
      this.translate.instant('RECIPE.VOCABULARY.HOURS')
    );
    const minutesComponent = this.formatDurationComponent(
      d.minutes,
      this.translate.instant('RECIPE.VOCABULARY.MINUTES')
    );

    result += hoursComponent;
    if (result.length > 0) {
      result += ` `;
    }
    result += minutesComponent;
    return result;
  }

  // Refactor: move to formatters
  private formatDurationComponent(value: string, unit: string) {
    let result = '';
    if (value && value.length > 0 && value !== '0') {
      result += `${value} ${unit}`;
    }
    return result;
  }

  private getRecipeImageUris = (recipe: Recipe): string[] => {
    const urls = recipe?.recipeImageSignedUrls;
    return !urls
      ? []
      : urls
        .filter(image => !!image.resourceURI)
        .map(image => image.resourceURI);
  };
}
